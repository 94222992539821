<template>
  <div class="userStatisticsMain">
    <el-scrollbar style="width:100%;height: 100%;">
      <div
        class="top"
        style="margin-bottom: 12px;"
        v-loading="tabLoading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 1)"
      >
        <div class="content-box">
          <div class="topHeader">
            <div class="titleBox">
              <BlockTitle :title="'数据概览'" />
            </div>
            <div class="topHeader-right">
              <el-button
                class="yesterday-btn"
                size="mini"
                :class="{ activeClass: overviewFlag }"
                @click="readYData"
                >昨日</el-button
              >
              <el-button
                class="today-btn"
                size="mini"
                :class="{ activeClass: !overviewFlag }"
                @click="readTData"
                >今日</el-button
              >
            </div>
          </div>
          <div class="content-top">
            <div
              class="topItem"
              v-for="(item, index) in topInfoArray"
              :key="index"
            >
              <img class="item-img" :src="item.img" alt="" />
              <div class="flex-right">
                <div class="topItemName">
                  {{ item.name }}
                </div>
                <div class="topItemValue">
                  {{ bigNumberTransform(item.value) }}
                </div>
                <div class="bottomItemValue" v-if="overviewFlag">
                  环比
                  <span
                    v-if="item.ratio != 0"
                    :style="{ color: item.ratio > 0 ? '#DF3737' : '#14B270' }"
                    >{{ item.ratio }}%</span>
                    <span
                    v-if="item.ratio == 0.0"
                    :style="{ color: item.ratio > 0 ? '#DF3737' : '#14B270' }"
                    >-</span>
                  <img
                    v-if="item.ratio > 0"
                    class="ratio-img"
                    :src="item.riseImgIcon"
                    alt=""
                  />
                  <img
                    v-if="item.ratio < 0"
                    class="ratio-img"
                    :src="item.dropImgIcon"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- noAdminHtml -->
      <!-- <div
        class="no-admin"
        v-if="!isAdmin"
        style="width: 100%; height: calc(100vh - 40vh);background-color: #fff;"
      ></div> -->
      <template>
        <div style="display: flex;">
          <!-- left -->
          <div style="width: calc(100% - 452px);">
            <div
              class="chart"
              v-loading="chartLoading"
              element-loading-text="拼命加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(255, 255, 255, 1)"
            >
              <div class="content-box">
                <div class="topHeader">
                  <div
                    class="titleBox"
                    style="width: 100%;display: flex;justify-content: space-between;align-items: center;"
                  >
                    <BlockTitle :title="'经营趋势'" />
                    <div class="operate-right">
                      <el-button
                        class="operate-one-btn"
                        :class="{ activeClass: operateFlag == 1 }"
                        size="mini"
                        @click="reado_QData"
                        >近7天</el-button
                      >
                      <el-button
                        class="operate-two-btn"
                        :class="{ activeClass: operateFlag == 2 }"
                        size="mini"
                        @click="reado_SData"
                        >近30天</el-button
                      >
                      <el-button
                        class="operate-three-btn"
                        :class="{ activeClass: operateFlag == 3 }"
                        size="mini"
                        @click="reado_EData"
                        >近12月</el-button
                      >
                    </div>
                  </div>
                </div>

                <div class="content" style="padding: 0;">
                  <div
                    ref="operateChart"
                    style="width:100%;height: 100%;"
                  ></div>
                </div>
              </div>
            </div>
            <div
              class="chart2"
              v-loading="chart2Loading"
              element-loading-text="拼命加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(255, 255, 255, 1)"
            >
              <div class="content-box" style="margin-top: 12px;">
                <div
                  class="titleBox"
                  style="width: 100%;display: flex;justify-content: space-between;align-items: center;"
                >
                  <BlockTitle :title="'充电时段分布'" />
                  <div class="charging-right">
                    <el-button
                      class="operate-one-btn"
                      :class="{ activeClass: chargingFlag == 1 }"
                      size="mini"
                      @click="readc_QData"
                      >近7天</el-button
                    >
                    <el-button
                      class="operate-two-btn"
                      :class="{ activeClass: chargingFlag == 2 }"
                      size="mini"
                      @click="readc_SData"
                      >近30天</el-button
                    >
                    <el-button
                      class="operate-three-btn"
                      :class="{ activeClass: chargingFlag == 3 }"
                      size="mini"
                      @click="readc_EData"
                      >近12月</el-button
                    >
                  </div>
                </div>
                <div class="content" style="padding: 0;">
                  <div ref="lineChart" style="width:100%;height: 100%;"></div>
                </div>
              </div>
            </div>
          </div>

          <!-- righ -->
          <div class="right-container">
            <div
              class="chart2"
              v-loading="chart3Loading"
              element-loading-text="拼命加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(255, 255, 255, 1)"
            >
              <!-- one -->
              <div class="currently-data">
                <div class="echart-title">充电枪实时状态</div>
                <div
                  class="content"
                  style="padding: 20px;background-color: #fff;height: calc(100% - 50px);width: 100%;margin: 25px 0;"
                >
                  <div ref="pieChart" style="width:100%;height: 100%;"></div>
                </div>
              </div>
              <!-- two -->
              <div class="ranking-data">
                <div class="content-box">
                  <div class="topHeader">
                    <div
                      class="titleBox"
                      style="width: 100%;display: flex;justify-content: space-between;align-items: center;"
                    >
                      <BlockTitle :title="'站点功率利用率排行'" />
                      <div class="ranking-right">
                        <el-button
                          class="operate-one-btn"
                          :class="{ activeClass: rankingFlag == 1 }"
                          size="mini"
                          @click="readr_QData"
                          >昨日</el-button
                        >
                        <el-button
                          class="operate-two-btn"
                          :class="{ activeClass: rankingFlag == 2 }"
                          size="mini"
                          @click="readr_SData"
                          >近7天</el-button
                        >
                        <el-button
                          class="operate-three-btn"
                          :class="{ activeClass: rankingFlag == 3 }"
                          size="mini"
                          @click="readr_EData"
                          >近30天</el-button
                        >
                      </div>
                    </div>
                  </div>
                  <template>
                    <el-table
                      :data="managePowerUtilization"
                      style="width: 100%"
                    >
                      <el-table-column
                        v-for="(column, index) in tableColumns"
                        :key="column.prop"
                        :prop="column.prop"
                        :label="column.label"
                        :width="column.width"
                      >
                        <template slot-scope="scope">
                          <template v-if="column.prop === 'listIndex'">
                            <el-image
                              v-if="scope.$index == 0"
                              style="width: 20px; height: 20px;vertical-align: middle;"
                              :src="showranking1"
                              fit="fill"
                            ></el-image>
                            <el-image
                              v-else-if="scope.$index == 1"
                              style="width: 20px; height: 20px;vertical-align: middle;"
                              :src="showranking2"
                              fit="fill"
                            ></el-image>
                            <el-image
                              v-else-if="scope.$index == 2"
                              style="width: 20px; height: 20px;vertical-align: middle;"
                              :src="showranking3"
                              fit="fill"
                            ></el-image>
                            <span v-else style="margin-left: 6px;">{{ scope.$index + 1 }}</span>
                          </template>

                          <template v-if="column.prop === 'plotName'">
                            <el-tooltip
                              class="item"
                              effect="dark"
                              :content="scope.row.plotName"
                              placement="top"
                            >
                              <span style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{ scope.row.plotName }}</span>
                            </el-tooltip>
                          </template>
                          <template v-if="column.prop === 'utilizationRate'">
                            <el-progress
                              :percentage="Number(scope.row.utilizationRate)"
                              :format="progressFormatter"
                              color="#18ADD0"
                              class="custom-progress"
                            ></el-progress>
                          </template>
                          <template v-if="column.prop === 'periodicChange'">
                            <span>{{ scope.row.periodicChange }} %</span>
                            <el-image
                              v-show="scope.row.periodicChange > 0"
                              style="vertical-align: middle; width: 16px; height: 16px"
                              :src="showratioup"
                              fit="fill"
                            ></el-image>
                            <el-image
                            v-show="scope.row.periodicChange < 0"
                              style="vertical-align: middle;width: 16px; height: 16px"
                              :src="showratiodown"
                              fit="fill"
                            ></el-image>
                          </template>
                        </template>
                      </el-table-column>
                    </el-table>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- <div
        class="table2"
        v-loading="tableLoading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 1)"
      >
        <div class="content-box">
          <div class="titleBox">
            <BlockTitle :title="'设备统计'" />
          </div>
          <div class="content">
            <el-table :data="tableDataEquip" border style="width: 100%">
              <el-table-column
                type="index"
                label="序号"
                align="center"
                width="80"
              ></el-table-column>
              <el-table-column
                prop="id"
                label="电桩编号"
                align="center"
                min-width="100"
                ><template slot-scope="scope">
                  <div class="num-text">
                    {{ scope.row.id }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="name"
                label="电桩名称"
                align="center"
                min-width="120"
              ></el-table-column>
              <el-table-column
                prop="amount"
                align="center"
                label="金额"
                min-width="80"
              >
                <template slot-scope="scope">
                  <div class="num-text">
                    {{ scope.row.amount }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="power"
                label="耗电量"
                align="center"
                min-width="120"
              >
                <template slot-scope="scope">
                  <div class="num-text">
                    {{ scope.row.power }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="usedNumber"
                label="使用频次"
                align="center"
                min-width="120"
              ></el-table-column>
              <el-table-column
                prop="goodPort"
                label="有效端口 "
                align="center"
                min-width="120"
              ></el-table-column>
              <el-table-column
                prop="badPort"
                label="损坏端口 "
                align="center"
                min-width="120"
              ></el-table-column>
              <el-table-column
                prop="runningStatesName"
                label="运行状态"
                align="center"
                min-width="120"
              >
                <template slot-scope="scope">
                  <img
                    src="@/assets/wifiGreen.png"
                    v-if="scope.row.runningStates == '0'"
                    style="width: 20px;height: 20px;"
                  />
                  <img
                    src="@/assets/wifi.png"
                    v-else
                    style="width: 20px;height: 20px;"
                  />
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              @current-change="handleCurrentChangeDevice"
              :current-page="page_device"
              :page-size="pageSize_device"
              :total="total_device"
            ></el-pagination>
          </div>
        </div>
      </div> -->
    </el-scrollbar>
    <el-dialog :visible.sync="dialogTableVisible">
      <span slot="title">
        <i class="el-icon-warning"></i>
        <span>是否确认下载?</span>
      </span>
      <div>
        此操作将下载当前页面下所有数据
      </div>
      <span slot="footer">
        <el-button @click="dialogTableVisible = false" type="default"
          >取 消</el-button
        >
        <el-button type="default" @click="exporthandler">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
//引入接口
import {
  getUsersAndSales,
  getSalesDetail,
  getSalesData,
  getPilesInfo,
  getRechargeDetail,
  exportExcel,
  getManageOverview,
  getManageTrendRevenue,
  getManageChargeTime,
  getManageGunStatus,
  getManagePowerUtilization,
} from "@/api/statistics";
import { getToken } from "@/utils/auth";
import { getPlotList } from "@/api/plot";
// 引入title组件
import BlockTitle from "@/components/blockTitle/blockTitle";
import MyStorage from "@/utils/cache";

export default {
  components: {
    BlockTitle,
  },
  data() {
    return {
      topInfoArray: [
        {
          name: "充电量 (度)",
          value: "",
          ratio: "",
          prop: "sumSales",
          url: "#",
          img: require("../../assets/index/index_1.png"),
          imgIcon: require("../../assets/index/ratioup_icon.png"),
          riseImgIcon: require("../../assets/index/ratioup_icon.png"),
          dropImgIcon: require("../../assets/index/ratiodown_icon.png"),
        },
        {
          name: "充电订单 (笔)",
          value: "",
          ratio: "",
          prop: "sumCount",
          url: "/orderList",
          img: require("../../assets/index/index_2.png"),
          imgIcon: require("../../assets/index/ratioup_icon.png"),
          riseImgIcon: require("../../assets/index/ratioup_icon.png"),
          dropImgIcon: require("../../assets/index/ratiodown_icon.png"),
        },
        {
          name: "充电营收 (元)",
          value: "",
          ratio: "",
          prop: "reSales",
          url: "/refund",
          img: require("../../assets/index/index_3.png"),
          imgIcon: require("../../assets/index/ratioup_icon.png"),
          riseImgIcon: require("../../assets/index/ratioup_icon.png"),
          dropImgIcon: require("../../assets/index/ratiodown_icon.png"),
        },
        {
          name: "充电服务费 (元)",
          value: "",
          prop: "userCount",
          url: "/registerUser",
          img: require("../../assets/index/index_4.png"),
          imgIcon: require("../../assets/index/ratiodown_icon.png"),
          riseImgIcon: require("../../assets/index/ratioup_icon.png"),
          dropImgIcon: require("../../assets/index/ratiodown_icon.png"),
        },
        // {
        //   name: "累计充值（元）",
        //   value: "",
        //   prop: "rechargeAmount",
        //   url: "#",
        //   img: require("../../assets/index/index_5.png"),
        // },
        // {
        //   name: "总站点数",
        //   value: "",
        //   prop: "plotList",
        //   url: "/device/plotList",
        //   img: require("../../assets/index/index_6.png"),
        // },
        // {
        //   name: "总设备数",
        //   value: "",
        //   prop: "deviceList",
        //   url: "/device/deviceList",
        //   img: require("../../assets/index/index_7.png"),
        // },
        // {
        //   name: "累计充电量(度)",
        //   value: "",
        //   prop: "totalPowerConsumption",
        //   url: "#",
        //   img: require("../../assets/index/index_8.png"),
        // },
        // {
        //   name: "运行设备",
        //   value: "",
        //   prop: "onLine",
        //   url: "#",
        //   img: require("../../assets/index/index_9.png"),
        // },
        // {
        //   name: "离线设备",
        //   value: "",
        //   prop: "offLine",
        //   url: "#",
        //   img: require("../../assets/index/index_10.png"),
        // },
      ],
      isAdmin: '',
      topInfoObject: {},
      myChart: "",
      barData: [],
      barData2: [],
      lineData: [],
      lineData2: [],
      lineData3: [],
      xData: [],
      tableLoading: true,
      chartLoading: true,
      chart2Loading: true,
      chart3Loading: true,
      tabLoading: true,
      page_device: 1,
      pageSize_device: 10,
      total_device: 0,
      tableDataEquip: [],
      myChart2: "",
      myChart3: "",
      amountLine: [],
      reAmountLine: [],
      amountXdata: [],
      dialogTableVisible: false,
      rankingData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          item: 11,
          address: "80",
        },
      ],
      tableColumns: [
        { prop: "listIndex", label: "排名", width: 50 },
        { prop: "plotName", label: "站点", width: 150 },
        { prop: "utilizationRate", label: "功率利用率", width: 140 },
        { prop: "periodicChange", label: "环比", width: 100 },
      ],
      overviewFlag: true,
      operateFlag: 1,
      chargingFlag: 1,
      rankingFlag: 1,
      operateChartObj: "",
      manageOverview_value: -1,
      manageOverview_unit: 0,
      manageTrendRevenue_value: -6,
      manageTrendRevenue_unit: 0,
      manageChargeTime_value: -6,
      manageChargeTime_unit: 0,
      manageGunStatus_value: 0,
      manageGunStatus_unit: 0,
      managePowerUtilization_value: -1,
      managePowerUtilization_unit: 0,
      manageOverview: [], //数据概览
      manageTrendRevenue: [], //经营趋势
      manageChargeTime: [], //充电时段分布
      manageGunStatus: [], //充电枪状态
      managePowerUtilization: [], //站点功率利用率排行
      showranking1: require("../../assets/index/ranking_1.png"),
      showranking2: require("../../assets/index/ranking_2.png"),
      showranking3: require("../../assets/index/ranking_3.png"),
      showratioup: require("../../assets/index/ratioup_icon.png"),
      showratiodown: require("../../assets/index/ratiodown_icon.png"),
    };
  },
  async mounted() {
    await getSalesData().then((res) => {
      this.tabLoading = true;
      this.topInfoObject = Object.assign(this.topInfoObject, res.data);
    });
    await getUsersAndSales().then((res) => {
      this.topInfoObject = Object.assign(this.topInfoObject, res.data);
      this.tabLoading = false;
    });
    await this.getPilesInfo();
    await this.topInfoArrayFun();
    await this.getSalesData();
    await this.getRechargeDetail();
    await this.getManageOverview(); //数据概览
    await this.getManageTrendRevenue(); //经营趋势
    await this.getManageChargeTime(); //充电时段分布
    await this.getManageGunStatus(); //充电枪实时状态
    await this.getManagePowerUtilization(); //站点功率利用率排行
    await this.getPlotList();
  },
  methods: {
    progressFormatter(percentage) {
      return `${percentage}%`;
    },
    openUrl(url) {
      this.$router.push(url);
    },
    formatTimestamps(timestamps, format) {
      var date = new Date(timestamps);
      var finalstr = format;
      finalstr = finalstr.replace("yyyy", date.getFullYear()); //年
      finalstr = finalstr.replace("mm", this.formatNum(date.getMonth() + 1)); //月
      finalstr = finalstr.replace("dd", this.formatNum(date.getDate())); //天
      finalstr = finalstr.replace("hh", this.formatNum(date.getHours())); //时
      finalstr = finalstr.replace("mi", this.formatNum(date.getMinutes())); //分
      finalstr = finalstr.replace("ss", this.formatNum(date.getSeconds())); //秒
      // finalstr = finalstr.replace('SSS', this.formatMilliseconds(date.getMilliseconds())); //毫秒
      //如果不想返回秒和毫秒，注释掉相应行数，传入参数时去掉该参数
      return finalstr;
    },
    formatNum(arg0) {
      let str = arg0.toString();
      if (str.length == 1) {
        return "0" + str;
      } else {
        return str;
      }
    },
    // ecahrtResize() {
    //   const elementResizeDetectorMaker = require("element-resize-detector");
    //   let erd = elementResizeDetectorMaker();
    //   var self = this;
    //   erd.listenTo(this.$refs.lineChart, function () {
    //     self.myChart2.resize()
    //     self.myChart.resize()
    //   });
    // },
    getPlotList() {
      getPlotList({ pageNo: 1, pageSize: 10 }).then((res) => {
        this.topInfoArray[5].value = res.data.total;
      });
    },
    getPilesInfo() {
      this.tableLoading = true;
      getPilesInfo({
        pageNo: this.page_device,
        pageSize: this.pageSize_device,
      }).then((res) => {
        this.topInfoArray[6].value = res.data.total;
        this.tableDataEquip = [...res.data.records];
        this.total_device = res.data.total;
        this.tableLoading = false;
        // this.topInfoArray[8].value = res.data.records.filter(item => {
        //   return item.runningStates == 0
        // }).length
        // this.topInfoArray[9].value = res.data.records.filter(item => {
        //   return item.runningStates == 1
        // }).length
      });
    },
    getRechargeDetail() {
      this.chart2Loading = true;
      getRechargeDetail({
        pageNo: 1,
        pageSize: 7,
      }).then((res) => {
        this.amountLine = res.data.records.map((item) => {
          return item.rechargeAmount;
        });
        this.reAmountLine = res.data.records.map((item) => {
          return item.refundAmount;
        });
        this.amountXdata = res.data.records.map((item) => {
          return item.rechargeDate;
        });
        this.chart2Loading = false;
        this.chart3Loading = false;
      });
    },
    getSalesData() {
      this.chartLoading = true;
      getSalesDetail({
        pageNo: 1,
        pageSize: 7,
      }).then((res) => {
        this.barData = res.data.records.map((item) => {
          return item.sumCount;
        });
        this.barData2 = res.data.records.map((item) => {
          return item.validCount;
        });
        this.lineData = res.data.records.map((item) => {
          return item.sumSales;
        });
        this.lineData2 = res.data.records.map((item) => {
          return item.reSales;
        });
        this.lineData3 = res.data.records.map((item) => {
          return item.reCount;
        });
        this.xData = res.data.records.map((item) => {
          return item.createTime;
        });
        this.chartLoading = false;
        // this.barChart();
      });
    },
    bigNumberTransform(value) {
      //单位转换
      const newValue = ["", "", ""];
      let fr = 1000;
      let num = 3;
      let text1 = "";
      let fm = 1;
      while (value / fr >= 1) {
        fr *= 10;
        num += 1;
      }
      // if (num <= 4) { // 千
      //   newValue[0] = parseInt(value / 1000) + ''
      //   newValue[1] = '千'
      // } else
      if (num <= 8) {
        // 万
        text1 = parseInt(num - 4) / 3 > 1 ? "千万" : "万";
        // tslint:disable-next-line:no-shadowed-variable
        fm = text1 === "万" ? 10000 : 10000000;
        if (value % fm === 0) {
          newValue[0] = parseInt(value / fm) + "";
        } else {
          newValue[0] = parseFloat(value / fm).toFixed(2) + "";
        }
        newValue[1] = text1;
      } else if (num <= 16) {
        // 亿
        text1 = (num - 8) / 3 > 1 ? "千亿" : "亿";
        text1 = (num - 8) / 4 > 1 ? "万亿" : text1;
        text1 = (num - 8) / 7 > 1 ? "千万亿" : text1;
        // tslint:disable-next-line:no-shadowed-variable
        fm = 1;
        if (text1 === "亿") {
          fm = 100000000;
        } else if (text1 === "千亿") {
          fm = 100000000000;
        } else if (text1 === "万亿") {
          fm = 1000000000000;
        } else if (text1 === "千万亿") {
          fm = 1000000000000000;
        }
        if (value % fm === 0) {
          newValue[0] = parseInt(value / fm) + "";
        } else {
          newValue[0] = parseFloat(value / fm).toFixed(2) + "";
        }
        newValue[1] = text1;
      }
      if (value < 1000) {
        newValue[0] = value + "";
        newValue[1] = "";
      }
      return newValue.join("");
    },
    getManageOverview() {
      //数据概览
      getManageOverview({
        value: this.manageOverview_value,
        unit: this.manageOverview_unit,
      }).then((res) => {
        this.topInfoArray.forEach((item, index) => {
          if (index == 0) {
            item.value = res.data.chargeCapacity.value;
            item.ratio = res.data.chargeCapacity.ratio;
          } else if (index == 1) {
            item.value = res.data.chargeOrder.value;
            item.ratio = res.data.chargeOrder.ratio;
          } else if (index == 2) {
            item.value = res.data.chargeRevenue.value;
            item.ratio = res.data.chargeRevenue.ratio;
          } else if (index == 3) {
            item.value = res.data.serviceAmount.value;
            item.ratio = res.data.serviceAmount.ratio;
          }
        });
      });
    },
    getManageTrendRevenue() {
      //经营趋势
      this.manageTrendRevenue = []; //清空
      getManageTrendRevenue({
        value: this.manageTrendRevenue_value,
        unit: this.manageTrendRevenue_unit,
      }).then((res) => {
        this.manageTrendRevenue = res.data; //经营趋势
        this.operateChart();
      });
    },
    getManageChargeTime() {
      //充电时段分布
      this.manageChargeTime = []; //清空
      getManageChargeTime({
        value: this.manageChargeTime_value,
        unit: this.manageChargeTime_unit,
      }).then((res) => {
        this.manageChargeTime = res.data; //充电时段分布
        this.lineChart();
      });
    },
    getManageGunStatus() {
      //充电枪实时状态
      this.manageGunStatus = []; //清空
      getManageGunStatus({
        value: this.manageGunStatus_value,
        unit: this.manageGunStatus_unit,
      }).then((res) => {
        this.manageGunStatus = res.data;
        this.pieChart();
      });
    },
    getManagePowerUtilization() {
      //站点功率利用率排行
      this.managePowerUtilization = []; //清空
      getManagePowerUtilization({
        value: this.managePowerUtilization_value,
        unit: this.managePowerUtilization_unit,
      }).then((res) => {
        this.managePowerUtilization = res.data;
      });
    },
    readYData() {
      //昨天
      this.overviewFlag = true;
      this.manageOverview_value = -1;
      this.getManageOverview();
    },
    readTData() {
      //今天
      this.overviewFlag = false;
      this.manageOverview_value = 0;
      this.getManageOverview();
    },
    reado_QData() {
      //经营趋势
      this.operateFlag = 1;
      this.manageTrendRevenue_value = -6;
      this.manageTrendRevenue_unit = 0;
      this.getManageTrendRevenue();
    },
    reado_SData() {
      //经营趋势
      this.operateFlag = 2;
      this.manageTrendRevenue_value = -29;
      this.manageTrendRevenue_unit = 0;
      this.getManageTrendRevenue();
    },
    reado_EData() {
      //经营趋势
      this.operateFlag = 3;
      this.manageTrendRevenue_value = -11;
      this.manageTrendRevenue_unit = 1;
      this.getManageTrendRevenue();
    },
    readc_QData() {
      //充电时段分布
      this.chargingFlag = 1;
      this.manageChargeTime_value = -6;
      this.manageChargeTime_unit = 0;
      this.getManageChargeTime();
    },
    readc_SData() {
      //充电时段分布 this.manageChargeTime_value
      this.chargingFlag = 2;
      this.manageChargeTime_value = -29;
      this.manageChargeTime_unit = 0;
      this.getManageChargeTime();
    },
    readc_EData() {
      //充电时段分布
      this.chargingFlag = 3;
      this.manageChargeTime_value = -11;
      this.manageChargeTime_unit = 1;
      this.getManageChargeTime();
    },
    readr_QData() {
      //站点功率利用率排行
      this.rankingFlag = 1;
      this.managePowerUtilization_value = -1;
      this.managePowerUtilization_unit = 0;
      this.getManagePowerUtilization();
    },
    readr_SData() {
      //站点功率利用率排行
      this.rankingFlag = 2;
      this.managePowerUtilization_value = -6;
      this.managePowerUtilization_unit = 0;
      this.getManagePowerUtilization();
    },
    readr_EData() {
      //站点功率利用率排行
      this.rankingFlag = 3;
      this.managePowerUtilization_value = -29;
      this.managePowerUtilization_unit = 0;
      this.managePowerUtilization = []; //清空
      this.getManagePowerUtilization();
    },
    topInfoArrayFun() {
      this.topInfoArray.forEach((currentItem) => {
        for (const key in this.topInfoObject) {
          if (key == currentItem.prop) {
            currentItem.value = this.topInfoObject[key];
          }
        }
      });
    },
    operateChart() {
      let monthData = this.manageTrendRevenue;
      let newTimeData = []; //时间
      let chargeDegree = []; //充电量
      let chargeServiceFee = []; //服务费
      let chargeRevenue = []; //营收
      let showFormat = this.operateFlag == 3 ? "{value}月" : "{value}日";
      monthData.chargeDegree.forEach((item) => {
        //时间全部统一
        newTimeData.push(item.time);
        chargeDegree.push(item.value); //充电量value
      });
      monthData.chargeRevenue.forEach((item) => {
        //营收value
        chargeRevenue.push(item.value);
      });
      monthData.chargeServiceFee.forEach((item) => {
        //服务费value
        chargeServiceFee.push(item.value);
      });
      this.operateChartObj = this.$echarts.init(this.$refs.operateChart);
      const imgs = [
        { img: require("../../assets/index/operate_one.png") },
        { img: require("../../assets/index/operate_two.png") },
        { img: require("../../assets/index/operate_three.png") },
      ];
      let option = {
        grid:{ 
            left:"5%",
            right:"5%",
          },
        color: ["#18ADD0", "#FF4C13", "#FFC005"],
        legend: {
          left: "center",
          bottom: 10,
          itemHeight: 18,
          itemWidth: 18,
          data: [
            { name: "充电量", icon: "image://" + imgs[0].img },
            { name: "充电营收", icon: "image://" + imgs[2].img },
            { name: "充电服务费", icon: "image://" + imgs[1].img },
          ],
        },
        tooltip: {
          trigger: "axis",
          extraCssText:
            "background: #fff; border-radius: 0;box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);color: #333;",
          formatter: (params) => {
            // params 是一个数组，数组中包含每个系列的数据信息
            let result = this.operateFlag == 3 ? `${params[0].axisValue}月<br/>` : `${params[0].axisValue}日`;
            // let result = `${params[0].axisValue}日<br/>`;
            params.forEach(function(item, index) {
              // item 是每一个系列的数据
              const seriesName = item.seriesName; // 系列名称
              const value = item.value; // 数据值
              const marker = item.marker; // 标志图形
              if (seriesName == "充电量") {
                //充电量
                result += `<div style="display: flex;align-items: center;justify-content: space-between;color:gray;line-height: 30px;">
								<span><img style="height: 18px;vertical-align: middle;margin-right:5px" src="${
                  imgs[0].img
                }" />${seriesName}:</span>
                  <span>${value}${index === 0 ? "度" : "元"}</span>
                </div>`;
              } else if (seriesName == "充电营收") {
                //充电营收
                result += `<div style="display: flex;align-items: center;justify-content: space-between;color:gray;line-height: 30px;">
								<span><img style="height: 18px;vertical-align: middle;margin-right:5px" src="${
                  imgs[2].img
                }" />${seriesName}:</span>
                  <span>${value}${index === 0 ? "元" : "元"}</span>
                </div>`;
              } else {
                //充电服务费
                result += `<div style="display: flex;align-items: center;justify-content: space-between;color:gray;line-height: 30px;">
								<span><img style="height: 18px;vertical-align: middle;margin-right:5px" src="${
                  imgs[1].img
                }" />${seriesName}:</span>
                  <span>${value}${index === 0 ? "元" : "元"}</span>
                </div>`;
              }
            });
            return result;
          },
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: true, //底部坐标
            axisLabel: {
              formatter: showFormat,
              textStyle: {
                color: "#86909C",
              },
            },
            axisLine: {
              lineStyle: {
                color: "#D9D9D9",
              },
            },
            data: newTimeData,
            splitNumber: 7 
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "单位：(度)",
            nameTextStyle: {
              color: "#86909C",
              fontSize: 12,
              lineHeight: 40,
            },
            axisLabel: {
              textStyle: {
                color: "#666",
              },
            },
            splitLine: {
              lineStyle: {
                type: "dashed",
                color: "#E9E9E9",
              },
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
          },
          {
            type: "value",
            name: "单位：(元)",
            nameTextStyle: {
              color: "#86909C",
              fontSize: 12,
              lineHeight: 40,
            },
            axisLabel: {
              textStyle: {
                color: "#666",
              },
            },
            splitLine: {
              lineStyle: {
                type: "dashed",
                color: "#E9E9E9",
              },
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "充电量",
            type: "line",
            smooth: true,
            showSymbol: false,
            symbolSize: 8,
            zlevel: 3,
            data: chargeDegree,
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(24, 173, 208,0.1)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(58,132,255, 0)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
          {
            name: "充电营收",
            type: "line",
            smooth: true,
            showSymbol: false,
            symbolSize: 8,
            zlevel: 3,
            data: chargeRevenue,
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(255, 76, 19, 0.1)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(58,132,255, 0)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            yAxisIndex: 1,
          },
          {
            name: "充电服务费",
            type: "line",
            smooth: true,
            showSymbol: false,
            symbolSize: 8,
            zlevel: 3,
            data: chargeServiceFee,
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(255, 76, 19, 0.1)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(58,132,255, 0)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            yAxisIndex: 1,
          },
        ],
      };

      this.operateChartObj.setOption(option);
      window.addEventListener("resize", () => {
        this.operateChartObj.resize();
      });
    },
    lineChart() {
      let timeData = this.manageChargeTime;
      let newTimeData = [];
      let chargeTimeValue = [];
      timeData.forEach((item) => {
        //时间全部统一
        newTimeData.push(item.time);
        chargeTimeValue.push(item.value);
      });

      this.myChart2 = this.$echarts.init(this.$refs.lineChart);

      let option2 = {
        grid:{ 
          top:'50px',
            bottom: '20px',
            left:"30px",
            right:"0px",
          },
        tooltip: {
          trigger: "axis",
          extraCssText:
            "background: #fff; border-radius: 0;box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);color: #333;",
          formatter: function(params) {
            // params 是一个数组，数组中包含每个系列的数据信息
            let result = `${params[0].axisValue}<br/>
						<span style="color:grey;margin-right:10px">充电量</span> ${
              params[0].data
            }<span>度</span>
						`;
            // params.forEach(function(item,index) {
            // 	// item 是每一个系列的数据
            // 	const seriesName = item.seriesName; // 系列名称
            // 	const value = item.value; // 数据值
            // 	const marker = item.marker; // 标志图形
            // 	result += `<div style="display: flex;align-items: center;justify-content: space-between;color:gray;line-height: 30px;">
            // 		<span><img style="height: 18px;vertical-align: middle;margin-right:5px" src="${imgs[index]}" />${seriesName}:</span>
            // 		<span>${value}${index === 0 ? '度':'元'}</span>
            // 	</div>`;
            // });
            return result;
          },
          axisPointer: {
            label: {
              show: false,
            },
            lineStyle: {
              color: "#18ADD0",
            },
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisLabel: {
            show: true,
            formatter: "{value}",
            textStyle: {
              color: "#aaaaaa",
              fontSize: 8,
            },
          },
          axisLine: {
            lineStyle: {
              color: "#D9D9D9",
            },
          },
          data: newTimeData,
        },
        yAxis: [
          {
            type: "value",
            name: "单位：(度)",
            nameTextStyle: {
              color: "#666",
              fontSize: 12,
              lineHeight: 40,
            },
            axisLabel: {
              textStyle: {
                color: "#666",
              },
            },
            splitLine: {
              lineStyle: {
                type: "dashed",
                color: "#E9E9E9",
              },
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            data: chargeTimeValue,
            type: "line",
            smooth: true,
            showSymbol: false,
            symbolSize: 8,
            zlevel: 3,
            // 线条样式
            itemStyle: {
              color: "#18ADD0",
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(24, 173, 208, 0.1)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(58,132,255, 0)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
        ],
      };
      this.myChart2.setOption(option2);
      window.addEventListener("resize", () => {
        this.myChart2.resize();
      });
      // this.ecahrtResize()
    },

    pieChart() {

      let prectenY =
        this.manageGunStatus.y / this.manageGunStatus.total != 0
          ? (this.manageGunStatus.y / this.manageGunStatus.total).toFixed(1)
          : this.manageGunStatus.y / this.manageGunStatus.total;
      let prectenN =
        this.manageGunStatus.n / this.manageGunStatus.total != 0
          ? (this.manageGunStatus.n / this.manageGunStatus.total).toFixed(1)
          : this.manageGunStatus.n / this.manageGunStatus.total;
      let prectenO =
        this.manageGunStatus.o / this.manageGunStatus.total != 0
          ? (this.manageGunStatus.o / this.manageGunStatus.total).toFixed(1)
          : this.manageGunStatus.o / this.manageGunStatus.total;
      let prectenF =
        this.manageGunStatus.f / this.manageGunStatus.total != 0
          ? (this.manageGunStatus.f / this.manageGunStatus.total).toFixed(1)
          : this.manageGunStatus.f / this.manageGunStatus.total;
      let prectenD =
        this.manageGunStatus.d / this.manageGunStatus.total != 0
          ? (this.manageGunStatus.d / this.manageGunStatus.total).toFixed(1)
          : this.manageGunStatus.d / this.manageGunStatus.total;

      this.myChart3 = this.$echarts.init(this.$refs.pieChart);
      let option3 = {
        tooltip: {
          trigger: 'item',
          formatter: function(params) {
            return '<div class="tooltip">' + JSON.parse(JSON.stringify(params)).name  + '</div>';
          }
          //  "{a} <br/>{b}"
        },
        legend: {
          top: "30%",
          left: "right",
          orient: "vertical",
          icon: "circle",
          align: "left",
          itemWidth: 10, // 图形宽度
          itemHeight: 10 // 图形高度

        },
        series: [
          {
            name: "",
            type: "pie",
            radius: ["50%", "60%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 2,
              borderColor: "#fff",
              borderWidth: 2,
            },
            avoidLabelOverlap: true,
            center: ["30%", "50%"],
            label: {
              normal: {
                show: true,
                position: "center", // 展示在中间位置
                formatter: () => {
                  return `{aD|${this.manageGunStatus.total}}\n\n{aL|全部}`;
                },
                rich: {
                  aD: {
                    color: "#1D2129",
                    fontSize: 20,
                    fontWeight: 900,
                  },
                  aL: {
                    fontSize: 14,
                    color: "#86909C",
                  },
                },
              },
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 20,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: this.manageGunStatus.y,
                name:
                  "充电中" +
                  "\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020" +
                  this.manageGunStatus.y,
                itemStyle: { color: "#18ADD0" },
              },
              {
                value: this.manageGunStatus.n,
                name:
                  "空闲" +
                  "\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020" +
                  this.manageGunStatus.n,
                itemStyle: { color: "#33D2BF" },
              },
              {
                value: this.manageGunStatus.o,
                name:
                  "占用" +
                  "\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020" +
                  this.manageGunStatus.o,
                itemStyle: { color: "#FF9900" },
              },
              {
                value: this.manageGunStatus.f,
                name:
                  "故障" +
                  "\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020" +
                  this.manageGunStatus.f,
                itemStyle: { color: "#FF4C13" },
              },
              {
                value: this.manageGunStatus.d,
                name:
                  "离线" +
                  "\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020" +
                  this.manageGunStatus.d,
                itemStyle: { color: "#E0E5E7" },
              },
            ],
          },
        ],
      };
      this.myChart3.setOption(option3);
      window.addEventListener("resize", () => {
        this.myChart3.resize();
      });
    },
    handleCurrentChangeDevice(val) {
      this.page_device = val;
      this.getPilesInfo();
    },
    exporthandler() {
      let userId = MyStorage.getItem("userId");
      exportExcel({ userId: userId }).then((res) => {
        const blob = new Blob([res], {
          type: "application/vnd.ms-excel;charset=utf-8",
        });
        const fileName =
          "数据统计" +
          this.formatTimestamps(new Date().getTime(), "yyyy-mm-dd") +
          ".xls"; //我这里是文件名加上时间，可以根据自己需求来
        // 生成临时的URL
        const url = URL.createObjectURL(blob);

        // 创建一个链接元素
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName; // 替换为你的文件名

        // 模拟点击链接来触发文件下载
        link.click();

        // 释放URL对象
        URL.revokeObjectURL(url);
        this.dialogTableVisible = false;
        // const elink = this.$refs.export;
        // elink.download = fileName;
        // elink.href = (URL.createObjectURL(blob));
        // // URL.revokeObjectURL(elink.href); // 释放URL 对象
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "Alternate-font";
  src: url("~@/assets/iconfont/alternate.ttf");
}

.userStatisticsMain {
  background: #f7f8fa;
  width: 100%;
  position: relative;

  .content-box {
    height: 100%;
    padding: 16px;
    background: #ffffff;
  }

  // 右边图表样式
  .right-container {
    padding-left: 12px;

    .currently-data {
      height: 100%;
      padding: 16px;
      background: #fff;

      .echart-title {
        font-weight: 600;
        font-size: 16px;
        color: #1d2129;
      }
    }

    .ranking-data {
      height: 100%;
      margin-top: 12px;

      .content-box {
        height: 100%;
        padding: 16px;
        background: #ffffff;
      }

      .topHeader {
        margin-bottom: 16px;
      }
    }
  }

  .custom-progress {
    position: relative;
    padding-left: 26px;
  }

  /* 自定义标题样式 */
  ::v-deep .el-table .el-table__header-wrapper thead tr th {
    font-size: 14px;
    color: #86909c;
    /* 标题字体颜色 */
    font-weight: 400;
    /* 标题字体加粗 */
  }

  ::v-deep .el-progress__text {
    position: absolute;
    left: 12px;
    /* 文字放置在左侧 */
    text-align: left;
    transform: translateX(-50%);
    /* 根据需要调整，使文字对齐 */
    width: 3em;
    /* 文字容器的宽度 */
  }
  ::v-deep .el-progress-bar {
    margin-left: 12px;
  }

  .downloadExcel {
    font-size: 14px;
    margin-right: 5px;
    cursor: pointer;
  }

  .downloadExcel:hover {
    color: #1a5cd6;
  }

  ::v-deep .el-scrollbar__view {
    > div {
      margin-bottom: 20px;
    }

    padding: 20px;
    overflow-x: hidden;
  }

  .top {
    width: 100%;

    .content-top {
      width: 100%;
      margin-top: 16px;
      display: flex;
      // flex-wrap: wrap;
      align-items: center;

      .topItem {
        .item-img {
          padding-left: 14px;
          height: 64px;
          height: 64px;
          margin-right: 16px;
        }

        &:nth-child(5n) {
          margin-left: 0;
        }

        margin-right: 16px;
        background-color: #fff;
        padding: 10px;
        // width: calc(20% - 16px);
        width: calc(25.5% - 16px);
        margin-bottom: 20px;
        height: 110px;
        display: flex;
        align-items: center;
        border-radius: 4px;
        border: 1px solid #e5e6eb;
        font-size: 26px;
        color: #666666;

        .topItemName {
          font-size: 14px;
          color: #4e5969;
          margin-bottom: 8px;
        }

        .topItemValue {
          margin-bottom: 12px;
          color: #1d2129;
          font-family: "Alternate-font";
        }

        .bottomItemValue {
          font-size: 12px;
          color: #86909c;

          span {
            margin: 0 2px 0 4px;
            font-size: 14px;
            color: #df3737;
          }

          .ratio-img {
            vertical-align: text-top;
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }

  .chart {
    height: 400px;
  }

  .chart2 {
    height: 400px;
  }

  .table2 {
    width: 100%;
  }

  .topHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .topHeader-right {
    display: flex;
    padding: 3px;
    line-height: 24px;
    border-radius: 2px;
    font-size: 12px;
    color: #057c98;
    background: #f2f3f5;

    .yesterday-btn {
      padding: 1px 12px;
      border-radius: 2px;
      border: none;
      cursor: pointer;
      background: transparent;
    }
    .yesterday-btn:hover {
      color: #057c98;
    }
    .today-btn:hover {
      color: #057c98;
    }

    .today-btn {
      cursor: pointer;
      border: none;
      background: transparent;
    }

    .activeClass {
      color: #057c98;
      background: #ffffff;
    }
  }

  .trendBox-right {
    display: flex;
    padding: 3px;
    line-height: 24px;
    border-radius: 2px;
    font-size: 12px;
    color: #057c98;
    background: #f2f3f5;

    .yesterday-btn {
      padding: 1px 12px;
      background: #ffffff;
      border-radius: 2px;
      cursor: pointer;
    }

    .today-btn {
      margin-left: 12px;
      cursor: pointer;
    }
  }

  .operate-right,
  .charging-right,
  .ranking-right {
    display: flex;
    padding: 3px;
    line-height: 24px;
    border-radius: 2px;
    font-size: 12px;
    color: #057c98;
    background: #f2f3f5;

    .operate-two-btn {
      padding: 1px 12px;
      border: none;
      background-color: transparent;
      border-radius: 2px;
      cursor: pointer;
    }

    .operate-one-btn {
      border: none;
      background-color: transparent;
      cursor: pointer;
    }
    .operate-one-btn:hover {
      color: #057c98;
    }
    .operate-two-btn:hover {
      color: #057c98;
    }
    .operate-three-btn:hover {
      color: #057c98;
    }

    .operate-three-btn {
      border: none;
      background-color: transparent;
      cursor: pointer;
    }

    .activeClass {
      color: #057c98;
      background: #ffffff;
    }
  }

  .titleBox {
    width: 200px;
    height: 20px;
  }

  .content {
    padding: 20px;
    background-color: #fff;
    height: calc(100% - 50px);
    width: 100%;
    margin: 25px 0;
  }

  .num-text {
    font-size: 14px;
    color: #337ab7;
  }

  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}

::v-deep .el-dialog {
  width: 20%;

  .el-button--primary {
    padding: 0 !important;

    a {
      display: inline-block;
      padding: 12px 20px;
    }
  }
}

.echarts-tooltip {
  box-shadow: 0px 2px 6px 0px rgba(175, 175, 175, 0.84);
}
</style>
